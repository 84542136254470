import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormDefault from '~/components/FormDefault';
import { Container } from './styles';
import Loja from '~/components/Loja';
import Separator from '~/components/Separator';
import { Default } from './data';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { Col, Row } from 'react-bootstrap';
import { InputMesAno } from './components/InputMesAno';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaSpedFiscal } from './validations';
import { InputSelect } from './components/InputSelect';
import TableLogs from './components/TableLogs';
import { toast } from 'react-toastify';
import spedContribuicoesApi from './services';
import { useQuery } from 'react-query';
import {
  StateControllScreen,
  StateInfoProcesso,
  FormDataReactHook,
  RequestDataNotificacao,
  RequestDataCriaProcesso,
} from './protocols';
import { InputText } from '~/components/NovosInputs';
import getDateFormated from './utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

const updateStateProperty = <T, K extends keyof T>(
  state: T,
  property: K,
  value: T[K],
) => {
  return { ...state, [property]: value };
};

const SpedContribuicoes: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const [lojas, setLojas] = useState<number[] | number>([]);
  const [infoProcesso, setInfoProcesso] = useState<StateInfoProcesso>({
    codProcesso: 0,
    tipoStatus: 0,
  });
  const [controlScreen, setControlScreen] = useState<StateControllScreen>({
    clearLoja: false,
    clearSelect: false,
    gerandoArquivo: false,
    showTableLog: false,
  });
  const [logs, setLogs] = useState<any[]>([]);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [refetchLoopLogs, setRefetchLoopLogs] = useState<boolean>(false);
  const [page, setIsPage] = useState<number>(1);
  const {
    register,
    setValue,
    control,
    watch,
    clearErrors,
    setError,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSpedFiscal),
    reValidateMode: 'onBlur',
  });
  const watchTipoEscrituracao = watch('tipo_escrituracao');
  useEffect(() => {
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    const mes = new Date().getMonth() + 1;
    const ano = new Date().getFullYear();
    setValue('mes', mes);
    setValue('ano', ano);
  }, []);

  const resetFormData = () => {
    setControlScreen((prevState) => ({
      ...prevState,
      clearLoja: true,
      clearSelect: true,
    }));
    setValue('tipo_escrituracao', null);
    setValue('num_recibo', '');
    setIsPage(1);
    setTimeout(() => {
      setControlScreen((prevState) => ({
        ...prevState,
        clearLoja: false,
        clearSelect: false,
      }));
    });
  };

  // Função para atualizar uma propriedade específica do estado
  const updateInfoProcesso = <K extends keyof StateInfoProcesso>(
    property: K,
    value: StateInfoProcesso[K],
  ) => {
    const updatedState = updateStateProperty(infoProcesso, property, value);
    // Atualizando o estado com o novo valor
    setInfoProcesso(updatedState);
  };

  const fetchLogs = useCallback(async () => {
    try {
      const { codProcesso } = infoProcesso;
      const getLogs = await spedContribuicoesApi.getLogs(codProcesso);
      const onlyLogs = getLogs.data.filter(
        (item: any) => !('tipo_status' in item),
      );
      const onlyStatus = getLogs.data.find(
        (item: any) => 'tipo_status' in item,
      );
      if (onlyStatus) {
        updateInfoProcesso('tipoStatus', onlyStatus.tipo_status);
        if (onlyStatus.tipo_status === 2) {
          setControlScreen((prevState) => ({
            ...prevState,
            gerandoArquivo: false,
          }));
          resetFormData();
          setRefetchLoopLogs(false);
        }
      }
      setLogs(onlyLogs);
      const paginasTotais = Math.ceil(onlyLogs.length / 5);
      setIsPage(paginasTotais);
    } catch (error) {
      toast.warn('Não foi possível buscar Logs do processo');
    }
  }, [isMounted, infoProcesso]);

  const { refetch: refetchLogs } = useQuery(
    `sped-contribuicoes-processo[${infoProcesso.codProcesso}]`,
    fetchLogs,
    {
      enabled: refetchLoopLogs,
      refetchInterval: 5000,
    },
  );

  const handleLojas = useCallback(
    async (Lojas: number | number[]) => {
      const newLojas = Array.isArray(Lojas) ? Lojas : new Array(Lojas);
      setLojas(newLojas);
    },
    [lojas],
  );

  const prepareData = (data: FormDataReactHook): RequestDataCriaProcesso => {
    return {
      cod_lojas: lojas,
      num_mes: Number(data.mes),
      num_ano: Number(data.ano),
      tipo_escrituracao: data.tipo_escrituracao.value,
      num_recibo: data.num_recibo,
    };
  };

  const prepareDataNotification = useCallback(
    (link: string): RequestDataNotificacao => {
      const { codProcesso } = infoProcesso;
      const requestData = {
        link,
        cod_lojas: lojas,
        cod_processo: codProcesso,
      };
      return requestData;
    },
    [infoProcesso, lojas],
  );

  const createNotification = useCallback(
    async (link: string) => {
      const requestData = prepareDataNotification(link);
      await spedContribuicoesApi.generateNotification(requestData);
    },
    [prepareDataNotification],
  );

  const handleDownload = useCallback(async () => {
    toast.success('Efetuando download do arquivo...');
    try {
      const { codProcesso } = infoProcesso;
      const response = await spedContribuicoesApi.downloadZip(codProcesso);
      const blob = response;

      // Cria um URL para o blob
      const url = window.URL.createObjectURL(blob);

      // Cria um link <a> temporário
      const link = document.createElement('a');
      link.href = url;
      link.download = `sped-contribuicoes-${codProcesso}`;
      link.rel = 'noreferrer noopener';
      // Adiciona o link ao corpo do documento
      document.body.appendChild(link);

      // Simula o clique no link para iniciar o download
      link.click();
      createNotification(url);
      // Remove o link do corpo do documento
      document.body.removeChild(link);
      refetchLogs();
    } catch (error) {
      setTimeout(
        () => toast.warn('Não foi possível realizar o download do arquivo'),
        150,
      );
    }
  }, [infoProcesso]);

  useEffect(() => {
    if (infoProcesso.tipoStatus === 2) {
      toast.success('Processo concluído com sucesso!');
      handleDownload();
    }
  }, [infoProcesso.tipoStatus, infoProcesso.codProcesso]);

  function CreateTable(itens: any): void {
    const AlertTableHeader =
      '<tr><th>Loja</th><th>Data</th><th>PDV</th><th>STATUS LEITURA</th></tr>';
    const AlertTableBody = itens.map((item: any): string => {
      const zonedDate = utcToZonedTime(item.dta_venda, 'UTC');
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.cod_loja}${spaco}</td><td>${format(
        zonedDate,
        'dd/MM/yyyy',
      )}${spaco}</td><td>${item.num_pdv}${spaco}</td><td>${
        item.des_tipo_status
      }${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Regularize a situação para prosseguir.
            </p>
            `;
    MySwal.fire({
      icon: 'info',
      width: 800,
      title:
        'Vendas abaixo não foram conferidas. \n Verifique na tela de "Conferência de Vendas"',
      html: String(AlertTable),
    });
  }

  const verificaFechamento = async (): Promise<any> => {
    const { mes, ano } = getValues();
    const { dtaIni, dtaFim } = getDateFormated(mes, ano);
    const requestData = {
      dtaIni,
      dtaFim,
      codLoja: lojas,
    };

    const response = await spedContribuicoesApi.verificaFechamento(requestData);

    if (response.success) {
      return { itens: response.data, success: response.data.length > 0 };
    }
    return { itens: [], success: false };
  };

  const onClick = handleSubmit(async (data) => {
    if (data.tipo_escrituracao.value === 1) {
      if (data.num_recibo.length < 41) {
        toast.warn(
          'Tamanho do Nº Recibo inválido. Informe todos os caracteres do campo',
        );
        setError('num_recibo', {
          type: 'error',
        });
        return;
      }
    }
    const { itens, success } = await verificaFechamento();
    if (success) {
      CreateTable(itens);
      return;
    }
    try {
      setLogs([]);
      setControlScreen((prevState) => ({
        ...prevState,
        gerandoArquivo: true,
      }));
      const requestData = prepareData(data as FormDataReactHook);
      const result = await spedContribuicoesApi.createProcess(requestData);
      if (result.success && result.cod_processo) {
        updateInfoProcesso('codProcesso', result.cod_processo);
        setControlScreen((prevState) => ({
          ...prevState,
          showTableLog: true,
        }));
        setRefetchLoopLogs(true);
      }
    } catch (error: any) {
      setControlScreen((prevState) => ({
        ...prevState,
        gerandoArquivo: false,
      }));
      toast.warn(error.message);
    }
  });

  const changeLabelSeparator = () => {
    const { codProcesso } = infoProcesso;
    if (controlScreen.showTableLog) {
      return `Logs da execução do processo #${codProcesso}`;
    }
    return 'Logs da execução do processo ';
  };

  return (
    <Container>
      <FormDefault
        codTela={285}
        title="SPED EFD-Contribuições"
        isNew={false}
        isClear={false}
        isSave
        hideFooter
        isCancel={false}
        isDelete={false}
        onSave={() => []}
        onCancel={() => []}
        isUpdate={false}
        onNew={() => []}
        onDelete={() => []}
        onClearFields={() => []}
        onReturnSearch={() => []}
      >
        <Row className="mb-2">
          <Col className="col-md-12 mb-2">
            <Loja
              disabled={controlScreen.gerandoArquivo}
              isMulti
              resetLojas={controlScreen.clearLoja}
              onChange={(value) => handleLojas(value)}
            />
          </Col>
          <Separator labelText="Parâmetros" color="black" />
          <Col md={0} style={{ marginRight: '1rem' }}>
            <InputMesAno
              disabled={controlScreen.gerandoArquivo}
              control={control}
              register={register}
              label="Mês/Ano"
              nameMes="mes"
              nameAno="ano"
              maxAno={9999}
              minAno={2024}
              maxLengthAno={4}
              maxLengthMes={2}
              isErrorAno={!!errors.ano}
              isErrorMes={!!errors.mes}
            />
          </Col>
          <Col md={5} style={{ marginRight: '1rem' }}>
            <InputSelect
              disabled={controlScreen.gerandoArquivo}
              control={control}
              options={Default.escrituracao}
              register={register}
              setValue={setValue}
              isError={!!errors.tipo_escrituracao}
              clearCampo={controlScreen.clearSelect}
              placeholder="Selecione"
              label="Tipo Escrituração"
              name="tipo_escrituracao"
              changeSelected={(value) => {
                clearErrors('tipo_escrituracao');
                setValue('num_recibo', '');
                setValue('tipo_escrituracao', value);
              }}
            />
          </Col>
          <Col md={5}>
            {watchTipoEscrituracao?.value === 1 && (
              <InputText
                name="num_recibo"
                label="Nº Recibo"
                placeholder="Informe o Nº Recibo"
                register={register}
                disabled={controlScreen.gerandoArquivo}
                isError={!!errors.num_recibo}
                onChange={() => clearErrors('num_recibo')}
                maxLength={41}
              />
            )}
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            justifyItems: 'flex-end',
            width: '100%',
            marginTop: '2rem',
          }}
        >
          <button
            type="button"
            className="btnGerarArquivo"
            disabled={controlScreen.gerandoArquivo}
            onClick={() => {
              onClick();
            }}
          >
            <BsFillLightningChargeFill size={20} />
            GERAR ARQUIVO
          </button>
        </div>
        {controlScreen.showTableLog && (
          <>
            <Separator
              labelText={changeLabelSeparator()}
              fontSize="16px"
              color="black"
            />
            <TableLogs
              logs={logs}
              rowsLabel={controlScreen.showTableLog}
              pageAuto={page}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                justifyItems: 'flex-end',
                width: '100%',
                marginTop: '0.5rem',
              }}
            >
              <button
                type="button"
                className="btnDownloadArquivo"
                disabled={infoProcesso.tipoStatus < 2}
                onClick={() => handleDownload()}
              >
                {infoProcesso.tipoStatus >= 2
                  ? 'Baixar arquivos'
                  : 'Gerando arquivo(s)...'}
              </button>
              <button
                type="button"
                disabled={!controlScreen.showTableLog}
                style={{
                  width: '150px',
                  height: '40px',
                  fontSize: '16px',
                  backgroundColor: '#8850bf',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '3px',
                }}
                onClick={() => fetchLogs()}
              >
                Atualizar
              </button>
            </div>
          </>
        )}
      </FormDefault>
    </Container>
  );
};

export default SpedContribuicoes;
